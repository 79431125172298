import { useEffect } from "react";
import "../styles/globals.css";
import { AppPropsWithLayout } from "../utils/datautils";
import getMainActivity from "services/mainActivity";
import { NavbarProvider, SolutionType } from "components/NavbarContext";
import { slugMapping } from "utils/mainActivityUtils";
import React from "react";
import { useRouter } from "next/router";
import Loading from "components/Loading";

declare global {
  interface Window {
    dataLayer: any;
  }
}

function MyApp({
  Component,
  pageProps,
  solutions,
}: AppPropsWithLayout & { solutions: SolutionType[] }) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const [isLoading, setLoading] = React.useState(false);
  const router = useRouter();

  useEffect(() => {
    const isBrowser = typeof window !== "undefined";

    isBrowser &&
      window.dataLayer &&
      window.dataLayer.push({
        event: "categoryProduct",
        categoryProduct: "rcs",
      });
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const start = async () => {
      setLoading(true);
    };
    const end = async () => {
      setLoading(false);
    };

    router.events.on("routeChangeStart", start);
    router.events.on("routeChangeComplete", end);
    router.events.on("routeChangeError", end);

    return () => {
      router.events.off("routeChangeStart", start);
      router.events.off("routeChangeComplete", end);
      router.events.off("routeChangeError", end);
    };
  }, []);

  return (
    <NavbarProvider solutions={solutions}>
      {isLoading && <Loading />}
      {getLayout(<Component {...pageProps} />)}
    </NavbarProvider>
  );
}

MyApp.getInitialProps = async ({ ctx }: any) => {
  const locale = ctx.locale || "id";
  const rawData = await getMainActivity(locale);
  const solutions: SolutionType[] = rawData.data.data.map((item: any) => {
    const activity_name = item.attributes.activity_name;
    const activity_icon =
      item.attributes.activity_icon?.data?.attributes?.url || "";
    const activity_icon_hover =
      item.attributes.activity_icon_hover?.data?.attributes?.url || "";
    const mappedSlug = slugMapping[activity_name];

    return {
      activity_name,
      activity_icon,
      activity_icon_hover,
      path: `/${mappedSlug}`,
    };
  });

  return { solutions };
};

export default MyApp;
