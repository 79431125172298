import rcsAxios from "./rcsAxios";

export default async function getMainActivity(lang: string) {
    try {
        return await rcsAxios({
            url: `/main-activities?locale=${lang}&populate=activity_icon&populate=activity_icon_hover&populate=expert.expert_image&populate=banner.banner_image&populate=clients.client_image&populate=seo.metaImage&populate=seo.metaSocial.image&populate=sectors&populate=sectors.sector_data&populate=testimonials&populate=testimonials.photo_200px_x_200px`,
        })
    } catch (error) {
        throw error;
    }
}