import axios from "axios";
import getConfig from "next/config";
const { serverRuntimeConfig } = getConfig();

const rcsAxios = axios.create({
  baseURL: serverRuntimeConfig.CMS_API_URL,
  headers: {
    Authorization: `Bearer ${serverRuntimeConfig.CMS_API_TOKEN}`,
  },
});

export default rcsAxios;
