import React, { useEffect, useRef } from "react";

import styles from "../styles/loading.module.css";

const Loading = () => {
  return (
    <>
      <div className={styles.loading}>
        <img
          src="/assets/rcs_loading.png"
          alt="Loading Animation"
          className={styles.loading_animation}
          crossOrigin="anonymous"
        />
      </div>
    </>
  );
};

export default Loading;
