import { createContext, useContext, ReactNode } from "react";

export type SolutionType = {
  activity_name: string;
  activity_icon: string;
  activity_icon_hover: string;
  path: string;
};

interface NavbarContextProps {
  solutions: SolutionType[];
}

const NavbarContext = createContext<NavbarContextProps | undefined>(undefined);

export const useNavbar = () => {
  const context = useContext(NavbarContext);
  if (!context) {
    throw new Error("Error");
  }
  return context;
};

export const NavbarProvider = ({
  children,
  solutions,
}: {
  children: ReactNode;
  solutions: SolutionType[];
}) => {
  return (
    <NavbarContext.Provider value={{ solutions }}>
      {children}
    </NavbarContext.Provider>
  );
};
